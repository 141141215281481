import styled from "styled-components"
import media from "styled-media-query";

export const WrapperTitleSolutions = styled.div`
  margin-bottom: 50px;
  ${media.lessThan("small")`
    margin: 0 30px;
      .img-Our {
        display: none!important;
      }
    `}
    .extra-my {
      margin-top: 80px;
      margin-bottom: 80px;
      &:nth-child(1) {
        color: red;
      }
      ${media.lessThan("small")`
        &:last-child {
          margin-bottom: 20px;
        }
      `}
    }
    ${media.between("medium", "large")`
      margin: 0 50px;
    `}
`

export const WrapperT = styled.div`
  width: 100%;
`

export const TitleSolutions = styled.h3`
  width: 100%;
  margin-top: 20px;
  margin-bottom: 80px;
  font-size: 40px;
  font-family: "Josefin Sans", sans-serif;
  color: ${props => props.theme.colors.subtitle};
  font-weight: 600;
  line-height: 20px;
  text-align: center;

  ${media.lessThan("small")`
    text-align: center;
    font-size: 25px;
    margin-bottom: 20px;
    > hr {
    width: 180px;
    margin-left: 2px;
    margin-top: 1px;
    opacity: 0.7;
  }
  `}
  ${media.between("medium", "large")`
    font-size: 35px;
    margin-bottom: 40px;
    > hr {
    width:230px;
    margin-left: 2px;
    margin-top: 1px;
    opacity: 0.7;
    }
    `}
`

export const TitleWorks = styled.h5`
    font-family: 'Josefin Sans';
    font-size: 25px;
    font-weight: 600;
    color: #4A4A4A;
    line-height: 30px;
    ${media.between("medium", "large")`
      margin-top: 50px;
    }
    `}
`

export const ContentWorks = styled.p`
    font-family: 'Josefin Sans';
    font-size: 25px;
    font-weight: lighter;
    color: #4A4A4A;
    line-height: 30px;
    ${media.lessThan("small")`
    font-size: 16px;
    `}
`

export const WrapperButton = styled.div`
  display: flex;
  justify-content: center;
  // margin-top: 80px;
  ${media.lessThan("small")`
    //margin: 10px 15px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  `}
`

export const ButtonSend = styled.button`
  width: 216px;
  height: 53px;
  background-color: rgba(2, 122, 209, 0.3);
  transition: color 0.5s;
  transition: background-color 0.5s;
  color: #027AD1;
  font-size: 16px;
  font-weight: 600;
  border-radius: 26px;
  margin-right: 40px;
  &:hover {
  color: ${props => props.theme.colors.white};
  background-color: ${props => props.theme.colors.success}; 
  }
    ${media.lessThan("small")`
      font-size: 16px;
      width: 190px;
      height: 44px;
      margin-right: 0px;
    `}
`

export const ButtonDemo = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 216px;
  height: 53px;
  padding-left: 10px;
  padding-right: 10px;
  background-color: #7EBA2A;
  color: ${props => props.theme.colors.white};
  font-size: 16px;
  font-family: "Josefin Sans", sans-serif;
  border-radius: 26px;
  transition: color 0.5s;
  transition: background-color 0.5s;

  &:hover {
    color: ${props => props.theme.colors.primary};
    background-color: ${props => props.theme.colors.info};
  }
  ${media.lessThan("small")`
      width: 190px;
      height: 44px;
      margin-top: 30px;
  `}
`