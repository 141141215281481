// yourTodoIcon.svg
import React from "react"
import { withTrans } from "../../i18n/withTrans"

import YourTodoIcon from "../../icons/yourTodoIcon.svg"

const YourTodoIconComponent = ({ t, i18n }) => (
  <div>
      <YourTodoIcon/>
  </div>
)

export default withTrans(YourTodoIconComponent)