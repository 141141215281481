import React from "react"
import * as S from "./styled"
import { withTrans } from "../../i18n/withTrans"
import AniLink from "gatsby-plugin-transition-link/AniLink"

import YourCompanyIconComponent from "../IconsComponents/yourCompanyIcon"
import YourInsightsIconComponent from "../IconsComponents/yourInsightsIcon"
import YourOnboardingIconComponent from "../IconsComponents/yourOnboardingIcon"
import YourTodoIconComponent from "../IconsComponents/yourTodoIcon"


const Works = ({ t, i18n }) => (
  <S.WrapperTitleSolutions>
    <div className="container">
      <div className="column">
        <S.WrapperT>
            <S.TitleSolutions>
                {t("works.mainTitle")}
            </S.TitleSolutions>
        </S.WrapperT>
      </div >
      <div className="container">
          <div className="columns">
                <div className="column is-flex is-justify-content-center">
                    <div>
                        <YourCompanyIconComponent/>
                    </div>
                </div>
                <div className="column">
                    <S.TitleWorks>{t("works.titleOne")}</S.TitleWorks><br/><br/>
                    <S.ContentWorks>{t("works.contentOne")}</S.ContentWorks>
                </div>
          </div>
          <div className="columns extra-my">
                <div className="column is-flex is-justify-content-center">
                    <div>
                        <YourInsightsIconComponent/>
                    </div>
                </div>
                <div className="column">
                    <S.TitleWorks>{t("works.titleTwo")}</S.TitleWorks><br/><br/>
                    <S.ContentWorks>{t("works.contentTwo")}</S.ContentWorks>
                </div>
          </div>
          <div className="columns extra-my">
                <div className="column is-flex is-justify-content-center">
                    <YourOnboardingIconComponent/>
                </div>
                <div className="column">
                    <S.TitleWorks>{t("works.titleThree")}</S.TitleWorks><br/><br/>
                    <S.ContentWorks>{t("works.contentTree")}</S.ContentWorks>
                </div>
          </div>
          <div className="columns extra-my">
                <div className="column is-flex is-justify-content-center">
                    <YourTodoIconComponent/>
                </div>
                <div className="column">
                    <S.TitleWorks>{t("works.titleFour")}</S.TitleWorks><br/><br/>
                    <S.ContentWorks>{t("works.contentFour")}</S.ContentWorks>
                </div>
          </div>
      </div>

      <div className="column">
      <S.WrapperButton>
            <AniLink to="/our-solutions">
                <S.ButtonSend>{t("solutions.titleOne")}</S.ButtonSend>
            </AniLink>
            <AniLink to="/contact">
                <S.ButtonDemo>{t("introSection.buttonRequest")}</S.ButtonDemo>
            </AniLink>
        </S.WrapperButton>
      </div >
    </div>
  </S.WrapperTitleSolutions>
)

export default withTrans(Works)
