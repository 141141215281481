import React from "react"
import { withTrans } from "../../i18n/withTrans"

import YourInsightsIcon from "../../icons/yourInsightsIcon.svg"

const YourInsightsIconComponent = ({ t, i18n }) => (
  <div>
      <YourInsightsIcon/>
  </div>
)

export default withTrans(YourInsightsIconComponent)