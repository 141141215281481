import React from "react"
import { withTrans } from "../../i18n/withTrans"

import YourCompanyIcon from "../../icons/yourCompanyIcon.svg"

const YourCompanyIconComponent = ({ t, i18n }) => (
  <div>
      <YourCompanyIcon/>
  </div>
)

export default withTrans(YourCompanyIconComponent)