import React from "react"
import { withTrans } from "../../i18n/withTrans"

import YourOnboardingIcon from "../../icons/yourOnboardingIcon.svg"

const YourOnboardingIconComponent = ({ t, i18n }) => (
  <div>
      <YourOnboardingIcon/>
  </div>
)

export default withTrans(YourOnboardingIconComponent)